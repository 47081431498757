<template>
    <v-container fluid class="ma-3 pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <v-tabs
                    class="info-tabs"
                    active-class="activeMyClass"
                    background-color="transparent"
                    center-active
                >
                    <v-tab @click="goTo('simuladorInformacoesGerais')">
                        Informações gerais
                    </v-tab>
                    <v-tab @click="goTo('simuladorOrientacoes')">
                        Orientações
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="12" class="pa-0">
                <template>
                    <router-view></router-view>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        goTo(rota) {
            this.$router.push({ name: rota });
        },
    },
};
</script>


<style>
.info-tabs .activeMyClass:hover:before {
    background: none;
}
.info-tabs .v-tab:before {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.info-tabs .v-tabs-slider {
    width: 70%;
    margin: 0 15%;
}
.info-tabs .v-tab {
    width: 250px;
    font-size: 1.2rem;
    font-weight: normal !important;
    letter-spacing: 0px;
    color: #999 !important;
    text-transform: none !important;
}
.info-tabs .activeMyClass {
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #000 !important;
}
</style>
